export interface Chain {
    name: string;
    cosmosId: string;
    pingMirror: string;
    valAddress: string;
    restApi: string;
    chainId: string;
    image: string;
}

const chains: Chain[] = [
  {
    name: 'Secret Network',
    cosmosId: 'secretnetwork',
    pingMirror: 'https://ping.pub/secret',
    valAddress: 'secretvaloper1ahawe276d250zpxt0xgpfg63ymmu63a0svuvgw',
    restApi: 'https://secret-4.api.trivium.network:1317',
    chainId: 'secret-4',
    image: 'secret.png',
  },
  {
    name: 'Archway',
    cosmosId: 'archway',
    pingMirror: 'https://ping.pub/archway',
    valAddress: 'archwayvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8mz786s',
    restApi: 'https://archway.api.trivium.network:1317',
    chainId: 'archway-1',
    image: 'archway.png',
  },
  {
    name: 'Sentinel Hub',
    cosmosId: 'sentinel',
    pingMirror: 'https://ping.pub/sentinel',
    valAddress: 'sentvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql82nz4kg',
    restApi: 'https://api-sentinel-ia.cosmosia.notional.ventures',
    chainId: 'sentinelhub-2',
    image: 'sentinel.png',
  },
  {
    name: 'Jackal Protocol',
    cosmosId: 'jackal',
    pingMirror: 'https://ping.pub/jackal',
    valAddress: 'jklvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8zq9eug',
    restApi: 'https://api.jackalprotocol.com',
    chainId: 'jackal-1',
    image: 'jackal.png',
  },
  {
    name: 'Dyson Protocol',
    cosmosId: 'dyson',
    pingMirror: 'https://ping.pub/dyson-protocol',
    valAddress: 'dysvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8nfc8sc',
    restApi: 'https://dyson.api.trivium.network:1317',
    chainId: 'dyson-mainnet-01',
    image: 'dyson.svg',
  },
  // {
  //   name: 'Akash Network',
  //   cosmosId: 'akash',
  //   pingMirror: 'https://ping.pub/akash',
  //   valAddress: 'akashvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8f5gq57',
  //   restApi: 'https://rest.cosmos.directory/akash',
  //   chainId: 'akashnet-2',
  //   image: 'akash.png',
  // },
  {
    name: 'Band Protocol',
    cosmosId: 'band',
    pingMirror: 'https://ping.pub/band',
    valAddress: 'bandvaloper1z5nwqcxltx89zt07jmw9jtx2jlerawsauptl6j',
    restApi: 'https://laozi1.bandchain.org/api',
    chainId: 'laozi-mainnet',
    image: 'band.png',
  },
  {
    name: 'Bitsong',
    cosmosId: 'bitsong',
    pingMirror: 'https://ping.pub/bitsong',
    valAddress: 'bitsongvaloper1lw4aqn2ce65tp80swj5gfz4hhw9hug8hd7ukrs',
    restApi: 'https://rest.cosmos.directory/bitsong',
    chainId: 'bitsong-2b',
    image: 'bitsong.svg',
  },
  {
    name: 'IRISnet',
    cosmosId: 'irisnet',
    pingMirror: 'https://ping.pub/iris',
    valAddress: 'iva1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8w7plzc',
    restApi: 'https://rest.cosmos.directory/irisnet',
    chainId: 'irishub-1',
    image: 'irisnet.png',
  },
  // {
  //   name: 'Vidulum',
  //   cosmosId: 'vidulum',
  //   pingMirror: 'https://ping.pub/vidulum',
  //   valAddress: 'vdlvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8s7l2ea',
  //   restApi: 'https://mainnet-lcd.vidulum.app',
  //   chainId: 'vidulum-1',
  //   image: 'vidulum.png',
  // },
  // {
  //   name: 'Kava',
  //   cosmosId: 'kava',
  //   pingMirror: 'https://ping.pub/kava',
  //   valAddress: 'kavavaloper1lvlvv4mllg768ay479h79e2wpv9wjm24gpukcs',
  //   restApi: 'https://rest.cosmos.directory/kava',
  //   chainId: 'kava_2222-10',
  //   image: 'kava.png',
  // },
  {
    name: 'RIZON',
    cosmosId: 'rizon',
    pingMirror: 'https://ping.pub/rizon',
    valAddress: 'rizonvaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql87543nx',
    restApi: 'https://rest.cosmos.directory/rizon',
    chainId: 'titan-1',
    image: 'rizon.png',
  },
  {
    name: 'Meme Network',
    cosmosId: 'meme',
    pingMirror: 'https://ping.pub/meme',
    valAddress: 'memevaloper1uvkmmjtvzxtn9cjhd2c9kdncjfslnql8quu63m',
    restApi: 'https://api-meme-1.meme.sx',
    chainId: 'meme-1',
    image: 'meme.png',
  },
  // {
  //   name: 'Intergalactic Data Exchange Protocol',
  //   cosmosId: 'idep',
  //   pingMirror: 'https://ping.pub/idep',
  //   valAddress: 'idepvaloper1qwt5w9mm2p5nayxhquetgvq5dm2aadhnt546xr',
  //   restApi: 'https://idep-api.lavenderfive.com',
  //   chainId: 'Antora',
  //   image: 'idep.png',
  // },
];

export default chains;